html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: system-ui, "Silkscreen";
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
